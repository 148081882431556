<template>
<div class="h-screen py-2">
    <Header :back="false" :language="lang" 
                  @langchanged="langchanged_"
                  :modeChanged="modeChanged">
      </Header>
    <div id="onBoardingSlider">
      <div v-if="card1Active == true" class="pt-16 flex flex-nowrap gap-10 overflow-x-hidden ml-44 tablet:ml-96 tablet:mt-0 mt-40">
        <OnboardingTablet order="1" :step="$t('onboarding.1')" :title="$t('onboarding.message')" :text="$t('onboarding.1text')" :isActive="card1Active"  class="flex-none" id="card1" />
        <OnboardingTablet order="2" :step="translate.step2" :title="translate.title2" :text="translate.text2" withPin :pin="pin" :isActive="card2Active" class="flex-none" />
        <OnboardingTablet order="3" :step="translate.step3" :title="translate.title3" :text="translate.text3" :isActive="card3Active" class="flex-none" />
        <OnboardingTablet order="4" :step="translate.step4" :title="translate.title4" :text="translate.text4" :isActive="card4Active" class="flex-none" />
      </div>
      <div v-else-if="card2Active == true" class="pt-16 flex flex-nowrap gap-10 overflow-x-hidden -ml-80 tablet:-ml-28 tablet:mt-0 mt-40">
        <OnboardingTablet order="1" :step="$t('onboarding.1')" :title="$t('onboarding.message')" :text="$t('onboarding.1text')" :isActive="card1Active"  class="flex-none" id="card1" />
        <OnboardingTablet order="2" :step="translate.step2" :title="translate.title2" :text="translate.text2" withPin :pin="pin" :isActive="card2Active" class="flex-none" />
        <OnboardingTablet order="3" :step="translate.step3" :title="translate.title3" :text="translate.text3" :isActive="card3Active" class="flex-none" />
        <OnboardingTablet order="4" :step="translate.step4" :title="translate.title4" :text="translate.text4" :isActive="card4Active" class="flex-none" />
      </div>
      <div v-else-if="card3Active == true" class="pt-16 flex flex-nowrap gap-10 overflow-x-hidden -ml-card3Portre tablet:-ml-card3 tablet:mt-0 mt-40">
        <OnboardingTablet order="1" :step="$t('onboarding.1')" :title="$t('onboarding.message')" :text="$t('onboarding.1text')" :isActive="card1Active"  class="flex-none" id="card1" />
        <OnboardingTablet order="2" :step="translate.step2" :title="translate.title2" :text="translate.text2" withPin :pin="pin" :isActive="card2Active" class="flex-none" />
        <OnboardingTablet order="3" :step="translate.step3" :title="translate.title3" :text="translate.text3" :isActive="card3Active" class="flex-none" />
        <OnboardingTablet order="4" :step="translate.step4" :title="translate.title4" :text="translate.text4" :isActive="card4Active" class="flex-none" />
      </div>
      <div v-else class="pt-16 flex flex-nowrap gap-10 overflow-x-hidden -ml-card4Portre tablet:-ml-card4 tablet:mt-0 mt-40">
        <OnboardingTablet order="1" :step="$t('onboarding.1')" :title="$t('onboarding.message')" :text="$t('onboarding.1text')" :isActive="card1Active"  class="flex-none" id="card1" />
        <OnboardingTablet order="2" :step="translate.step2" :title="translate.title2" :text="translate.text2" withPin :pin="pin" :isActive="card2Active" class="flex-none" />
        <OnboardingTablet order="3" :step="translate.step3" :title="translate.title3" :text="translate.text3" :isActive="card3Active" class="flex-none" />
        <OnboardingTablet order="4" :step="translate.step4" :title="translate.title4" :text="translate.text4" :isActive="card4Active" class="flex-none" />
      </div>
    </div>
    
    <!-- <div :class="card1Active == true ? 'pt-16 flex flex-nowrap gap-10 overflow-x-hidden ml-96' : 'pt-16 flex flex-nowrap gap-10 overflow-x-hidden -ml-28'">
      <OnboardingTablet :step="$t('onboarding.1')" :title="$t('onboarding.message')" :text="$t('onboarding.1text')" :isActive="card1Active"  class="flex-none" id="card1" />
      <OnboardingTablet :step="translate.step2" :title="translate.title2" :text="translate.text2" withPin :pin="pin" :isActive="card2Active" class="flex-none" />
      <OnboardingTablet :step="translate.step3" :title="translate.title3" :text="translate.text3" :isActive="card3Active" class="flex-none" />
      <OnboardingTablet :step="translate.step4" :title="translate.title4" :text="translate.text4" :isActive="card4Active" class="flex-none" />
    </div> -->
    <div class="flex items-center w-5/12 mx-auto mt-40 tablet:mt-0">
      <div class="flex-grow pr-6">
        <div class="overflow-hidden h-px text-xs flex rounded dark:bg-white bg-mercury-lightest">
          <div :style="currentProg" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-redDamask"></div>
        </div>
      </div>
    <div class="font-body text-bodyL text-redDamask flex-none w-10 tracking-wider">{{getProgress(progress)}}</div>
    </div>
    <div class="px-4 flex flex-row items-baseline">
      <Button :text="$t('login.tabletNewRegisterBtn')" href="/tablet-register" fullWidth buttonType="secondary" />
      <Button :text="translate.nextBtnText" fullWidth buttonType="primary" @click="[progressChange(progress), cardStep()]" class="tablet:ml-72 ml-20" /> 
    </div>
</div>
</template>

<script>

import Header from "@/components/Header.vue";
import OnboardingTablet from '@/components/tablet/OnboardingTablet.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'TabletOnBoarding',
  components: {
    OnboardingTablet,
    Header,
    Button
  },
  data() {
    return {
      pin: this.$route.query.pin,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      currentProg: '',
      progress: 'width: 25%',
      progress1: 'width: 50%',
      progress2: 'width: 75%',
      progress3: 'width: 100%',
      status: '',
      card1Active: true,
      card2Active: false,
      card3Active: false,
      card4Active: false,
    }
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  methods: {
    langchanged(value) {
        this.lang = value;
      },
    cardStep() {
      if( this.card1Active ) {
        this.card1Active = false
        this.card2Active = true
      } else if( this.card2Active ) {
        this.card2Active = false
        this.card3Active = true
      } else if( this.card3Active ) {
        this.card3Active = false
        this.card4Active = true
      }
    },
    getProgress: function(x) {
      let array = x.split(' ');
      return array[1];
    },
    progressChange: function(prog) {
      switch (prog) {
        case 'width: 25%' :
          this.progress = this.progress1,
          this.currentProg =this.progress1
          break;
        case 'width: 50%' :
          this.progress = this.progress2
          this.currentProg =this.progress2
          break;
        case 'width: 75%' :
          this.progress = this.progress3
          this.currentProg =this.progress3
          this.translate.nextBtnText = this.translate.finishBtnText
          break;
        case 'width: 100%' :
          this.$router.push('/tablet-register');
          break
        default:
          break;
      }
    },
  },
  computed: {
    translate() {
      return {
        step1: this.$t('onboarding.1'),
        title1: this.$t('onboarding.message'),
        text1: this.$t('onboarding.1text'),
        step2: this.$t('onboarding.2'),
        title2: this.$t('login.pin'),
        text2: this.$t('onboarding.2text'),
        step3: this.$t('onboarding.3'),
        title3: this.$t('onboarding.3title'),
        text3: this.$t('onboarding.3text'),
        step4: this.$t('onboarding.4'),
        title4: this.$t('onboarding.4title'),
        text4: this.$t('onboarding.4text'),
        nextBtnText: this.$t('onboarding.footerBtn'),
        finishBtnText: this.$t('onboarding.finishBtn'),
      }
    }
  },
}
</script>

<style>
@media (max-width: 1280px) {
  body {
    overflow-y: auto;
  }
}

</style>
