<template>
   <div :class="isActive ? 'p-8 w-tabletCard-w bg-halfColonialWhite rounded-2xl h-tabletCardActive -mt-8' : 'p-8 w-tabletCard-w bg-tabletCard rounded-2xl h-tabletCard'">
     <div class="bg-redDamask-opacity10 h-52 rounded-lg">
       <div v-if="withPin == true" class="flex flex-col justify-center text-center h-full">
         <p class="font-heading text-subS text-redDamask">{{ $t('login.pin') }}</p>
        <h1 class="font-heading text-h1 text-eternity">{{ pin }}</h1>
       </div>
       <div v-else-if="order == '1'">
         <img class="h-52 mx-auto" :src="welcome()" >
       </div>
       <div v-else-if="order == '3'">
         <img class="h-52 mx-auto" :src="qrCode()" >
       </div>
       <div v-else-if="order == '4'">
         <img class="h-52 mx-auto" :src="timeline()" >
       </div>
     </div>
      <p class="font-body text-subM text-redDamask pt-6">{{ step }}</p>
      <h3 :class="isActive ? 'font-heading text-h3 text-eternity pb-2' : 'font-heading text-h3 text-white pb-2'">{{ title }}</h3>
      <p :class="isActive ? 'font-body text-bodyM text-eternity' : 'font-body text-bodyM text-white'">{{ text }}</p>
    </div>
</template>

<script>
export default {
  name: 'OnboardingTablet',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    step: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    pin: {
      type: String,
      required: false
    },
    withPin: {
      type: Boolean,
      default: false
    },
    order: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      pinData: this.pin,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  methods: {
    qrCode(){
      return require("../../assets/slider/qrcode.png");
    },
    welcome(){
      return require("../../assets/slider/slide3.png");
    },
    timeline(){
      return require("../../assets/slider/slide5.png");
    },
  },
}
</script>

<style>

</style>